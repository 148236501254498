import { useQuery } from '@tanstack/react-query';

import { useServices } from '@/Services';

export function useCheckoutSession(id: string | null) {
  const { checkoutService } = useServices();
  return useQuery({
    queryKey: ['checkout-session', id],
    queryFn: () => checkoutService.getCheckoutSession(id ?? ''),
    enabled: id !== null,
  });
}
